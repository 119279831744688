<template>
    <div class="ProductsEdit full-height pa-4 d-flex flex-column ">
        <div class="d-flex full-height-footerButtons">
            <v-form ref="form">
                <div class="content-area pa-5 flex-grow-1 bg-white">
                    <v-row class="d-flex align-center">
                        <v-col class="col-2">
                            <p>{{ $t('products.productName') }}</p>
                        </v-col>
                        <v-col class="col-10">
                            <text-input inputName="editProduct-productName" @onInputFocus="onInputFocus" :readonly="(!editable && this.editProduct.id != null) || !checkUserRights('productsEdit')" :class="{'noAction': (!editable && this.editProduct.id != null) || !checkUserRights('productsEdit')}" :rules="[v => !!v || $t('validation.required'), rules.alphanumericSpecCharName, rules.maxLengthName]"/>
                        </v-col>
                    </v-row>
                    <v-row class="d-flex align-center">
                        <v-col class="col-2">
                            <p>{{ $t('products.number') }}</p>
                        </v-col>
                        <v-col class="col-10">
                            <text-input inputName="editProduct-number" @onInputFocus="onInputFocus" :readonly="(!editable && this.editProduct.id != null) || !checkUserRights('productsEdit')" :class="{'noAction': (!editable && this.editProduct.id != null) || !checkUserRights('productsEdit')}" :rules="[rules.alphanumericSpecCharValue, rules.maxLengthValue]"/>
                        </v-col>
                    </v-row>
                    <v-row class="d-flex align-center">
                        <v-col class="col-2">
                            <p>{{ $t('products.selectMethod') }}</p>
                        </v-col>
                        <v-col class="d-flex col-10">
                            <v-select :items="activeMethods" item-text="methodName" item-value="id" v-model="editProduct.method" class="editProduct-method-dropdown" outlined hide-details="auto" attach :readonly="(!editable && this.editProduct.id != null) || !checkUserRights('productsEdit')" :class="{'noAction': (!editable && this.editProduct.id != null) || !checkUserRights('productsEdit')}" :rules="[v => !!v || $t('validation.required')]"/>
                            <MethodInfoBox :method-info-modal="methodInfoModal" state="editProduct" @methodInfoModalClose="methodInfoModal = false" @methodInfoModalOpen="methodInfoModal = true"></MethodInfoBox>
                        </v-col>
                    </v-row>
                    <v-row class="d-flex align-center">
                        <v-col class="col-2">
                            <switch-on-off name="editProduct-status" :disabled="!checkUserRights('productsEdit')" :class="{'active': status}" class="mr-4"/>
                        </v-col>
                        <v-col class="col-6">
                            <p>{{ $t('general.status') }}</p>
                        </v-col>
                    </v-row>
                </div>
            </v-form>
        </div>
        <v-bottom-navigation grow dark background-color="disiBluegreyLight" class="pt-4" height="76">
            <footer-button></footer-button>
            <footer-button></footer-button>
            <footer-button buttonFunction="print" :successCheck="showPrintSuccess" :progress="showPrintProgress" :class="{'disabled': printDisabled || !checkUserRights('productsPrint')}" @footerButtonClick="print"></footer-button>
            <footer-button buttonFunction="delete" :class="{'disabled': !editable || editProduct.id == null || !checkUserRights('productsDelete')}" @footerButtonClick="deleteProduct"></footer-button>
            <footer-button buttonFunction="cancel" @footerButtonClick="goToProducts"></footer-button>
            <footer-button buttonFunction="save" :successCheck="showSaveSuccess" :progress="showSaveProgress" :class="{'disabled': saveDisabled || !checkUserRights('productsEdit')}" @footerButtonClick="save"></footer-button>
        </v-bottom-navigation>
    </div>
</template>

<script>
import TextInput from '@/components/TextInput.vue'
import SwitchOnOff from '@/components/SwitchOnOff.vue'
import FooterButton from '@/components/FooterButton.vue'
import MethodInfoBox from '@/components/MethodInfoBox.vue'
import {mapGetters, mapState} from "vuex";
import mixins from '../mixins/mixins';

export default {
    name: 'ProductsEdit',
    components: {
        TextInput,
        MethodInfoBox,
        SwitchOnOff,
        FooterButton,
    },
    props: {
    },
    data () {
        return {
            saveDisabled: true,
            showSaveProgress: false,
            showSaveSuccess: false,
            showPrintProgress: false,
            showPrintSuccess: false,
            originalProduct: {},
            methodInfoModal: false,
            rules: {
                alphanumericSpecCharName: v => {
                    if (v){
                        let alphanumericSpecChar = /^[ A-Za-z0-9*%&\-_/+?!@#.]+$/;
                        return alphanumericSpecChar.test(v) || `${this.$t('validation.pleaseEnterValidName')}`;
                    } else return true;
                },
                alphanumericSpecCharValue: v => {
                    if (v){
                        let alphanumericSpecChar = /^[ A-Za-z0-9*%&\-_/+?!@#.]+$/;
                        return alphanumericSpecChar.test(v) || `${this.$t('validation.pleaseEnterValidValue')}`;
                    } else return true;
                },
                maxLengthName: v => {
                    if (v){
                        return v.length < 24 || `${this.$t('validation.chooseShorterName')}`;
                    } else return true;
                },
                maxLengthValue: v => {
                    if (v){
                        return v.length < 24 || `${this.$t('validation.chooseShorterValue')}`;
                    } else return true;
                },
            }
        }
    },
    computed: {
        ...mapState([
            'editProduct',
        ]),
        ...mapGetters({
            activeMethods: 'activeMethods',
        }),
        editable(){
            return this.editProduct.editable;
        },
        printDisabled(){
            if (this.editProduct.id != null){
                return false;
            } else {
                return true;
            }
        },
        status(){
            return this.$store.state.editProduct.status;
        },
    },
    methods: {
        goToProducts(){
            this.$router.push('products');
        },
        print(){
            this.showPrintProgress = true;
            this.$store.dispatch('postAxiosNoSetter', ['/disi/products/print', {'id': parseInt(this.editProduct.id)}])
            .then(response =>{
                this.showPrintProgress = false;
                if (response.status === 200){
                    this.showPrintSuccess = true;
                    setTimeout(()=>{
                        this.showPrintSuccess = false;
                    }, 800)
                }
            });
        },
        deleteProduct(){
            this.$store.dispatch('postAxiosNoSetter', ['/disi/products/delete', {'id': parseInt(this.editProduct.id)}])
            .then(response=>{
                if (response.status === 200){
                    this.goToProducts();
                }
            })

        },
        save(){
            this.$refs.form.validate();
            if (this.$refs.form.validate()){
                this.showSaveProgress = true;
                if (this.editProduct.id != null){
                    let Product = {}
                    //optional
                    if (this.editProduct.productName != this.originalProduct.productName && this.editProduct.productName != ""){
                        Product.productName = this.editProduct.productName;
                    }
                    if (this.editProduct.number != this.originalProduct.number && this.editProduct.number != ""){
                        Product.number = this.editProduct.number;
                    }
                    if (this.editProduct.method != this.originalProduct.method){
                        Product.method = this.editProduct.method;
                    }
                    if (this.editProduct.status != this.originalProduct.status){
                        Product.status = this.editProduct.status;
                    }
                    if (Object.keys(Product).length === 0 && Product.constructor === Object){
                        this.showSaveProgress = false;
                        this.showSaveSuccess = true;
                        this.watcher();
                        setTimeout(()=>{
                            this.saveDisabled = true;
                            this.showSaveSuccess = false;
                        }, 800)
                    } else {
                        //must send
                        Product.id = parseInt(this.editProduct.id);
                        this.$store.dispatch('postAxiosNoSetter', ['disi/products/edit', Product])
                        .then(response=>{
                            this.showSaveProgress = false;
                            if (response.status === 200){
                                this.showSaveSuccess = true;
                                this.watcher();
                                setTimeout(()=>{
                                    this.saveDisabled = true;
                                    this.showSaveSuccess = false;
                                }, 800)
                            }
                        })
                    }
                } else {
                    let Product = {
                        //must send
                        "productName": this.editProduct.productName,
                        "number": this.editProduct.number,
                        "method": this.editProduct.method,
                        "status": this.editProduct.status
                    }
                    this.$store.dispatch('postAxiosNoSetter', ['disi/products/new', Product])
                    .then(response=>{
                        this.showSaveProgress = false;
                        if (response.status === 200){
                            this.editProduct.id = response.data.id;
                            this.showSaveSuccess = true;
                            this.watcher();
                            setTimeout(()=>{
                                this.saveDisabled = true;
                                this.showSaveSuccess = false;
                            }, 800)
                        }
                    })
                }
            }
        },
        watcher(){
            //copy editProduct to originalProduct for comparison
            this.originalProduct = JSON.parse(JSON.stringify(this.editProduct));

            let productName = this.$watch('$store.state.editProduct.productName', function() {
                this.saveDisabled = false;
                stopWatch();
            });

            let number = this.$watch('$store.state.editProduct.number', function() {
                this.saveDisabled = false;
                stopWatch();
            });

            let method = this.$watch('$store.state.editProduct.method', function() {
                this.saveDisabled = false;
                stopWatch();
            });

            let status = this.$watch('$store.state.editProduct.status', function() {
                this.saveDisabled = false;
                stopWatch();
            });

            const stopWatch = function(){
                productName();
                number();
                method();
                status();
            }
        },
        // Keyboard functions ------------------ //
        onInputFocus(input, onlyNumbers) {
            this.$emit('onInputFocus', [input.target.id, onlyNumbers])
            this.$vuetify.goTo('#'+input.target.id, {duration: 100, offset: 80, container: ".content-area"});
        },
    },
    mixins: [mixins],
    created() {
        this.$emit("resetInput");
        this.watcher();
    },
    beforeRouteLeave (to, from, next) {
        if(this.saveDisabled){
            next()
        } else {
            this.$confirm({message: this.$t('alerts.unsavedChanges'), show: true, cancelText: this.$t('general.cancel'), continueText: this.$t('general.continue') })
                .then(() => {
                    // continue
                    next();
                })
                .catch(() => {
                    // cancel
                });
        }
    },
}
</script>
<style scoped lang="scss">

.v-form{
    width: 100%;
}
</style>